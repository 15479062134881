












































import { Vue, Component, Prop, Watch, PropSync, Ref } from 'vue-property-decorator'
import { FindGrupoEconomicoUseCase } from '@/usecases'
import { Page, Pageable } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { GrupoEconomico } from '@/models/GrupoEconomico'

@Component
export default class SeletorDeGruposEconomicos extends Vue {
	@Ref() campo!: HTMLInputElement
	@PropSync('value') selecionado?: GrupoEconomico | GrupoEconomico[] | string | string[]
	@Prop({type: Array, default: () => []}) excludes!: string[]
	@Prop({ type: String }) idDoGrupoEconomicoSalvo!: string | null
	@Prop({ type: String }) usuario?: string
	
	gruposEconomicos: GrupoEconomico[] = []
	busca: string | null = null
	findGrupoEconomicoUseCase = new FindGrupoEconomicoUseCase()
	cancelToken: CancelTokenSource | null = null

	pageable: Pageable = {
		page: 0,
		size: 3,
	}
	pagina: Page<GrupoEconomico> | null = null
	carregando = true

	created() {
		this.carregarSelecao()
	}

	extrairId(objeto: GrupoEconomico | string): string {
		return typeof objeto === 'object'
			? objeto.id
			: objeto
	}

	async carregarSelecao() {
		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findGrupoEconomicoUseCase.findByParams({}, axiosConfig)
			this.pagina.content.forEach(grupoEconomico => {
				this.gruposEconomicos.push(grupoEconomico)
			})
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async find() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			this.pagina = await this.findGrupoEconomicoUseCase.findByParams({
				page: this.pageable.page || 0,
				size: this.pageable.size,
				busca: this.busca || undefined,
			},
			{
				cancelToken: this.cancelToken.token,
			})
			this.pagina.content.forEach(grupoEconomico => {
				if (this.gruposEconomicos.some(({ id }) => grupoEconomico.id === id)) return
				this.gruposEconomicos.push(grupoEconomico)
			})
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('busca', { deep: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.find()
	}

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1

		await this.find()
	}

	get gruposEconomicosFiltrados() {
		return this.gruposEconomicos
	}

	get carregouTodosOsGruposEconomicos() {
		return this.pagina?.last || false
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}

	focus() {
		this.campo.focus()
	}

}
