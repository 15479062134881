




























































import { Vue, Component, PropSync } from 'vue-property-decorator'
import { FindTipoDeClienteUseCase, SaveTipoDeClienteUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FormTipoDeCliente, TipoDeCliente } from '@/models'
import { criarTipoDeCliente } from '@/shareds/cliente-shareds'

@Component
export default class SeletorDeTipoDeCliente extends Vue {
	@PropSync('value', { type: Object, default: null })
	tipoDeCliente!: TipoDeCliente

	busca = ''
	itemEditando: ItemSelecao | null = null
	itensSelecao: ItemSelecao[] = []

	findTipoDeClienteUseCase = new FindTipoDeClienteUseCase()
	saveTipoDeClienteUseCase = new SaveTipoDeClienteUseCase()

	async created() {
		try {
			const tiposDeCliente = (await this.findTipoDeClienteUseCase.find()).content
			this.itensSelecao = tiposDeCliente.map<ItemSelecao>(item => ({
				...item,
				tipo: 'selecao',
			}))
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get itens() {
		const itens: ItemAutocompletar[] = [
			{
				tipo: 'header',
				header: 'Selecione uma opção ou crie uma',
			},
			...this.itensSelecao,
		]
		return !this.busca ||
			(this.itemSelecionado &&
				this.busca.toLowerCase() === this.itemSelecionado.nome.toLowerCase())
			? itens
			: [...itens, { tipo: 'novo' }]
	}

	get itemSelecionado(): ItemSelecao | null {
		if (!this.tipoDeCliente) return null
		return (
			this.itensSelecao.find(({ id }) => id === this.tipoDeCliente.id) || null
		)
	}

	async selecionaItem(item: ItemSelecao) {
		if (!item) {
			this.$emit('input', null)
			return
		}

		this.$emit('input', item)
	}

	async criarTipoDeCliente() {
		try {
			const tipoNovo: FormTipoDeCliente = {
				...criarTipoDeCliente(),
				nome: this.busca,
				expiraEm: null,
			}

			const tipoDeClienteCriado = await this.saveTipoDeClienteUseCase.create(tipoNovo)

			const item: ItemSelecao = { ...tipoDeClienteCriado, tipo: 'selecao' }
			this.itensSelecao.push(item)
			this.$emit('input', item)

			return tipoDeClienteCriado
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	editar(indice, item) {
		this.itemEditando = { ...item }
	}

	async confirmarEdicao() {
		if (!this.itemEditando) {
			return
		}
		if (!this.itemEditando.nome) {
			this.itemEditando = null
			return
		}
		const indice = this.itensSelecao.findIndex(
			item => item.id === this.itemEditando?.id,
		)
		if (indice === -1) return

		this.itensSelecao.splice(indice, 1, this.itemEditando)
		const tipoDeClienteEditado: FormTipoDeCliente = {
			...this.itemEditando,
			id: this.itemEditando.id,
		}

		try {
			await this.saveTipoDeClienteUseCase.update(tipoDeClienteEditado)
		} catch (e) {
			AlertModule.setError(e)
		}

		this.itemEditando = null
	}

	filtrar(item: ItemAutocompletar, queryText, itemText) {
		if (item.tipo === 'header') return false

		const hasValue = val => (val ? val : '')
		const textLower = hasValue(itemText)
			.toString()
			.toLowerCase()
		const queryLower = hasValue(queryText)
			.toString()
			.toLowerCase()

		if (item.tipo === 'novo') {
			return (
				queryLower &&
				!this.itensSelecao.some(({ nome }) => nome.toLowerCase() === queryLower)
			)
		}
		return textLower.includes(queryLower)
	}
}

export type ItemHeader = {
	tipo: 'header'
	header: string
}

export type ItemSelecao = TipoDeCliente & {
	tipo: 'selecao'
}

export type ItemNovo = {
	tipo: 'novo'
	text: string
}

export type ItemAutocompletar = ItemHeader | ItemSelecao | ItemNovo
