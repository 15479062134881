import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import { Loja } from '@/models'
import { search } from '@/shareds/search-utils'
import store from '@/store/vuex'

@Module({
	name: 'LojaStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class LojaStore extends VuexModule {
	busca = ''
	lojas: Loja[] = []

	lojaSelecionada: Loja | null = null

	@Mutation
	adicionaLoja(loja: Loja) {
		this.lojas.push(loja)
	}

	@Mutation
	atualizaLoja(loja: Loja) {
		const lojaIndex = this.lojas.findIndex(element => element.id === loja.id)
		lojaIndex === -1
			? this.lojas.push(loja)
			: this.lojas.splice(lojaIndex, 1, loja)
	}

	@Mutation
	setLojas(lojas: Loja[]) {
		this.busca = ''
		this.lojas = lojas
	}

	@Mutation
	setTermoBusca(termo: string) {
		this.busca = termo === null || termo === undefined ? '' : termo
	}

	get lojasFiltradas() {
		if (!this.busca) return this.lojas
		const filtered = search(this.lojas, this.busca)
		return filtered as Loja[]
	}

	get lojasOrdenadas() {
		return [...this.lojasFiltradas].sort(ordenarLojaPorNome)
	}
}

export function ordenarLojaPorNome(lojaA: Loja, lojaB: Loja) {
	return lojaA.nome.localeCompare(lojaB.nome)
}

export function ordenarLojasAgrupadasPorEstado(
	lojasPorEstadoA: LojasAgrupadasPorEstado,
	lojasPorEstadoB: LojasAgrupadasPorEstado,
) {
	return lojasPorEstadoA.estado.localeCompare(lojasPorEstadoB.estado)
}

export type LojasAgrupadasPorEstado = {
	estado: string
	lojas: Loja[]
}
export const LojaModule = getModule(LojaStore)
