










































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import { Loja, Pageable } from '@/models'
import { FindLojaUseCase } from '@/usecases'
import ListagemDeLojasPorCards from './ListagemDeLojasPorCards.vue'
import ListagemDeLojasPorLista from './ListagemDeLojasPorLista.vue'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		ListagemDeLojasPorCards,
		ListagemDeLojasPorLista,
	},
})
export default class BuscaDeLoja extends Vue  {
	@PropSync('value') selecionado!: Loja
	@Prop() encurtado!: boolean
	tipoDaListagem: 'grade' | 'lista' = 'grade'
	busca = ''
	findLojaUseCase = new FindLojaUseCase()
	lojas: Loja[] = []
	cancelToken: CancelTokenSource | null = null
	carregando = false

	page: Pageable = {
		page: 1,
		size: 5,
		sort: [],
	}

	totalPages = 1


	async created() {
		await this.findLojas()
	}


	async findLojas() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const lojasPage = await this.findLojaUseCase.find({
				page: this.page.page ? this.page.page - 1 : 0,
				size: 5,
				busca: this.busca || undefined,
			}, axiosConfig )


			this.totalPages = lojasPage.totalPages
			this.lojas = lojasPage.content
			this.carregando = false
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}
	get componenteDaListagem() {
		return this.tipoDaListagem === 'grade'
			? 'ListagemDeLojasPorCards'
			: 'ListagemDeLojasPorLista'
	}

	@Watch('busca')
	onChangeBusca() {
		this.lojas = []
		this.page.page = 1
		this.findLojas()
	}

	@Watch('page', { deep: true, immediate: true })
	onChangePageable() {
		this.lojas = []
		this.findLojas()
	}
}

